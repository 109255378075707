.start {
    border-bottom: 1px solid var(--light-border);
    padding-bottom: 20px;
}

.start-intro {
    display: flex;
    flex-direction: column;
}

.start-intro-text {
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.1em;
    margin: 10px 0;
}

.start-text {
    margin: 8px 0px;
}

.post {
    width: 100px;
    height: 100px;
    background: #FFFFFF15;
    border: 1px solid var(--light-border);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.post:hover {
    transition: 0.5s;
    border: 1px solid #6096B4;
    color: #6096B4;
}

.start-posts {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}

.white {
    margin: 0;
    text-align: center;
    font-size: 14px;
}

.desktop {
    display: none;
}

.flex-end {
    align-self: flex-end;
    justify-self: flex-end;
}

@media screen and (width > 600px) {
    .post {
        width: 160px;
        height: 120px;
    }

    .start {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .start-intro-text {
        font-size: 26px;
        font-weight: 700;
        line-height: 29px;
    }

    .start-posts {
        justify-content: flex-end;
        margin-top: 10px;
        min-width: 50%;
    }

    .desktop {
        display: flex;
    }
}