.skills-post {
    height: 100px;
    width: 100px;
    background: #FFFFFF15;
    border: 1px solid var(--light-border);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.skills-post:hover {
    transition: 0.5s;
    border: 1px solid #6096B4;
    color: #6096B4;
}

.skills {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}

.skills-container {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--light-border);
}