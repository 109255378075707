.modalBackground {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10;
    overflow-y: hidden;
}

.modalContent {
    max-width: 86%;
    padding: 12px;
    position: relative;
    background: #000000;
    color: #ffffff;
    border: 1px solid var(--light-border);
    border-radius: 12px;
    z-index: 11;
}

.modalImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 150px;
    height: 150px; */
}

.modalImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cancel {
    color: rgb(206, 94, 94);
    transform: rotate(45deg);
    position: absolute;
    top: 0px;
    right: 8px;
    cursor: pointer;
}

.cancelText {
    font-size: 40px;
    padding: 0;
    margin: 0;
}

.cancelText:hover {
    color: rgb(204, 132, 132);
}

@media screen and (width > 1300px) {
    .modalContent {
        max-width: 350px;
    }
}