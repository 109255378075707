.post-container {
    /* flex */
    width: 100%;
    border-bottom: 1px solid var(--light-border);
}

.post-title {
    font-size: 26px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.1em;
    margin: 10px 0px;
}

.post-intro {
    font-weight: bold;
}

.post-code {
    background-color: #181818;
    color: #ffffff;
    padding: 12px;
    border-radius: 10px;
}

.post-rows {
    display: flex;
    flex-direction: column;
}

.post-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 100%;
}

.post-list-container {
    display: flex;
    flex-direction: column;
}

.fit {
    margin-top: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
}



@media screen and (width > 600px) {
    .post-row {
        width: 50%;
    }

    .post-rows {
        flex-direction: row;
        gap: 20px;
    }

    .post-list {
        max-width: 60%;
    }
    

    .post-list-container {
        flex-direction: row;
        gap: 10px;
    }
}