@import url('https://fonts.googleapis.com/css2?family=Inter&family=Parisienne&display=swap');

html {
    --light-text: #111111;
}

body {
    font-family: 'Inter', 'Sans-serif';
    color: var(--light-text);
}

.home {
    width: 92%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

a {
    text-decoration: none;
    color: var(--light-text);
}

button {
    color: var(--light-text)
}

p {
    letter-spacing: 0.1em;
    font-size: 16px;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 18px;
}

h3 {
    font-size: 17px;
}

h4 {
    font-size: 16px;
}

ul {
    display: flex;
    padding-left: 20px;
    list-style-type: circle;
}

li {
    text-decoration: none;
}

.dark p, .dark h2, .dark h3, .dark h4, .dark a, .dark Link, .dark input[type=option], .dark ul, .dark li, .dark button {
    color: white;
}

@media screen and (width > 1300px) {
    .home {
        width: 1250px;
    }
}