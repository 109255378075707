.weather-form {
    display: flex;
    gap: 10px;
}

.weather-input {
    border-radius: 10px;
    border: solid 1px var(--light-border);
    padding: 8px;
    font-size: 16px;
}

.weather-button {
    padding: 12px 24px;
    border-radius: 10px;
    border: none;
    background: #6096B4;
    font-size: 16px;
    color: #ffffff;
}

.weather-info {
    text-decoration: underline;
}