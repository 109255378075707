.footer {
    display: flex;
    flex-direction: column;
}

.footer-text {
    padding-bottom: 10px;
}

.external-link {
    text-decoration: underline;
    cursor: pointer;
}

.external-link > a {
    word-break: keep-all;
}

.footer-ul {
    flex-direction: column;
}

.shrink {
    flex-shrink: 0;
}

.border {
    border-bottom: solid 1px var(--light-border);
}

@media screen and (width > 1300px) {
    .footer {
        flex-direction: row;
        gap: 40px;
    }
}