.forecastDays {
    display: flex;
    gap: 10px;
}

.forecastItem {
    border: 1px solid var(--light-border);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100px;
    height: 100px;
}

.forcastTempHigh {
    font-size: 20px;
    font-weight: 700;
    padding: 4px;
    margin: 0px;
    line-height: 1;
}

.forcastTempLow {
    font-size: 12px;
    padding: 0px;
    margin: 0px;
    line-height: 1;
}

.forcastDate {
    font-size: 10px;
    padding: 0px;
    margin-top: 16px;
    justify-self: flex-end;
}

.forecastError {
    color: brown;
}