.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--light-border);
    position: relative;
    padding: 12px 0px;
}

.nav-right {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.modeButton {
    margin: 0;
    padding: 0;
    font-weight: 300;
    border: 1px solid var(--light-border);
    border-radius: 9999px;
    background: #FFFFFF15;
    align-self: center;
    font-size: 15px;
    width: 40px;
    height: 40px;
}

.modeButton:hover {
    transition: 0.5s;
    border: 1px solid #6096B4;
    cursor: pointer;
    color: #6096B4;
}

.bold {
    text-transform: uppercase;
    font-size: 17px;
    margin: 8px 0px;
    letter-spacing: 0.1em;
}

.weight {
    font-weight: 500;
    font-size: 16px;
}

.settingsdark {
    background: #000000;
    border: 1px solid #252525;
    color: white;
}

.profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 9999px;
}

.form-text {
    margin: 0px 0px 8px 0px;
}

.form-flex {
    display: flex;
    align-items: center;
}

.margin {
    margin: 8px 0px;
}

input[type=radio] {
    margin: 3px;
}

@media screen and (width > 600px) {

    .modeButton {
        width: 60px;
        height: 60px;
    }
}